/*
 * @Author: mulingyuer
 * @Date: 2021-04-13 11:43:47
 * @LastEditTime: 2021-04-14 17:57:54
 * @LastEditors: mulingyuer
 * @Description:
 * @FilePath: \saas-admin-vue\src\modules\lottery\api\lottery-list.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 抽奖列表
export const lotteryList = data => {
  return api({
    url: '/admin/lottery/lottery/index',
    method: 'post',
    data
  });
};

// 抽奖-软删除
export const softDelete = data => {
  return api({
    url: '/admin/lottery/lottery/delete',
    method: 'post',
    data
  });
};

// 抽奖-硬删除
export const hardDelete = data => {
  return api({
    url: '/admin/lottery/lottery/del',
    method: 'post',
    data
  });
};


// 抽奖-还原
export const putBack = data => {
  return api({
    url: '/admin/lottery/lottery/putBack',
    method: 'post',
    data
  });
};


// 抽奖-编辑的详情
export const lotteryDetail = data => {
  return api({
    url: '/admin/lottery/lottery/detail',
    method: 'post',
    data
  });
};

// 抽奖-新增&编辑保存
export const lotterySave = data => {
  return api({
    url: '/admin/lottery/lottery/save',
    method: 'post',
    data
  });
};

//抽奖列表导出
export const exportDocument = data => {
  return download({
    url: '/admin/lottery/lottery/document',
    method: 'post',
    data,
    download: true,
  });
};