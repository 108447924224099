<!--
 * @Author: mulingyuer
 * @Date: 2021-04-14 11:08:21
 * @LastEditTime: 2021-05-17 16:41:52
 * @LastEditors: mulingyuer
 * @Description: 
 * @FilePath: \lottery\views\add-edit-basic.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="add-edit-basic">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="medium-form">
        <el-form-item label="标题：" prop="title">
          <el-input v-model="form.title" placeholder="请输入抽奖标题"></el-input>
        </el-form-item>
        <el-form-item label="抽奖时间：" prop="time">
          <DateTimePicker v-model="form.time" />
        </el-form-item>
        <el-form-item label="每日抽奖数：" prop="daily_limit">
          <el-input v-model.number="form.daily_limit"></el-input>
          <div class="info">
            <i class="el-icon-info"></i>每日抽奖数为每个用户每日可抽奖次数
          </div>
        </el-form-item>
        <el-form-item label="每人抽奖数：" prop="total_limit">
          <el-input v-model="form.total_limit"></el-input>
          <div class="info">
            <i class="el-icon-info"></i>每人抽奖数为每个用户可抽奖次数，为空则为无限制
          </div>
        </el-form-item>
        <el-form-item label="兑换须知：" prop="tips">
          <el-input class="no-limit" rows="7" type="textarea" v-model="form.tips"></el-input>
        </el-form-item>
        <el-form-item label="抽奖规则：" prop="rule">
          <el-input class="no-limit" rows="7" type="textarea" v-model="form.rule"></el-input>
        </el-form-item>
        <el-form-item label="分享标题：" prop="share_title">
          <el-input v-model="form.share_title"></el-input>
        </el-form-item>
        <el-form-item label="分享图片：" prop="share_image">
          <single-media-wall ratio="500:400" v-model="form.share_image" :width="185" :height="130">
            <template #info>
              <div class="share-img-info">建议上传尺寸比例为500*400</div>
            </template>
          </single-media-wall>
        </el-form-item>
      </el-form>
    </div>
    <!-- 悬浮按钮 -->
    <fixed-action-bar>
      <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading"
        @click="onNext('ruleForm')">下一步</el-button>
      <el-button size="medium" @click="onCancel">取消</el-button>
    </fixed-action-bar>
  </div>
</template>
<script>
import DateTimePicker from "@/base/components/Form/DateTimePicker";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { lotteryDetail, lotterySave } from "../api/lottery-list";
export default {
  data() {
    return {
      loading: false, //编辑数据加载中
      form: {
        id: 0, //0为新增
        title: "", //标题
        time: [-1, -1], //抽奖时间start_time，end_time
        daily_limit: "", //每日抽奖次数
        total_limit: "", //用户抽奖次数
        tips: "", //兑换须知
        rule: "", //抽奖规则
        share_title: "", //分享的标题
        share_image: "", //分享的图片
      },
      rules: {
        title: [{ required: true, message: "请输入抽奖标题", trigger: "blur" }],
        time: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              const flag = value.some((item) => {
                return item === -1 || item === "";
              });
              if (flag) {
                callback(new Error("请选择抽奖的时间"));
              } else {
                callback();
              }
            },
          },
        ],
        daily_limit: [
          {
            type: "integer",
            required: true,
            message: "请输入每日抽奖次数",
            trigger: "blur",
          },
        ],
        rule: [{ required: true, message: "请输入抽奖规则", trigger: "blur" }],
      },
      editData: {}, //api获取的编辑的原数据
      saveLoading: false, //保存中
    };
  },
  computed: {
    //抽奖数据的id
    lotteryId() {
      return this.$route.query.id;
    },
  },
  methods: {
    //取消
    onCancel() {
      this.$router.push({ name: "LotteryList" });
    },
    //下一步
    onNext(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = this.saveInitData();
          lotterySave(postData)
            .then((res) => {
              const { msg, data } = res;
              this.$message.success(msg);
              this.$router.push({
                name: "AddEditPrizeConfig",
                params: { id: data },
              });
              this.saveLoading = false;
              this.resetForm(formName);
            })
            .catch(() => {
              this.saveLoading = false;
            });
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({ block: "center" });
          }, 100);
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取详情
    getLotteryDetail() {
      if (typeof this.lotteryId !== "undefined") {
        this.loading = true;
        const postData = { id: this.lotteryId };
        lotteryDetail(postData)
          .then((res) => {
            const { data } = res;
            this.editData = data;
            //初始化表单
            this.initForm();
            this.loading = false;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    //初始化表单
    initForm() {
      const keys = Object.keys(this.form);
      keys.forEach((key) => {
        if (key === "time") {
          this.form[key] = [this.editData.start_time, this.editData.end_time];
        } else if (key === "total_limit") {
          if (this.editData[key] === -1) {
            this.form[key] = "";
          } else {
            this.form[key] = this.editData[key];
          }
        } else {
          this.form[key] = this.editData[key];
        }
      });
    },
    //保存前格式化数据
    saveInitData() {
      let newData = Object.assign({}, this.form);
      // 时间
      delete newData.time;
      newData.start_time = this.form.time[0];
      newData.end_time = this.form.time[1];
      // 用户抽奖次数
      if (newData.total_limit === "") newData.total_limit = -1;
      return newData;
    },
  },
  created() {
    this.getLotteryDetail();
  },
  //路由进入前
  beforeRouteEnter(to, from, next) {
    const index = to.matched.findIndex((item) => item.path === to.path);
    if (index >= 0) {
      const parentRoute = to.matched[index - 1];
      if (parentRoute) {
        if (typeof to.query.id !== "undefined") {
          parentRoute.meta.title = "编辑";
          parentRoute.meta.breadcrumb.title = "编辑";
        } else {
          parentRoute.meta.title = "新增";
          parentRoute.meta.breadcrumb.title = "新增";
        }
      }
    }
    next();
  },
  components: {
    DateTimePicker,
    SingleMediaWall,
    FixedActionBar,
  },
};
</script>
<style lang="scss" scoped>
.add-edit-basic {
  .content {
    padding: 20px 0;
    .no-limit {
      height: auto;
    }
    .share-img-info {
      margin-top: 15px;
      font-size: 14px;
      color: #909399;
    }
  }
}
</style>